import React from "react"
import { Global } from "@emotion/core"
import theme, { globalStyles } from "../styles/theme"
import Footer from "./Footer"

export default function Layout({ children }) {
  return (
    <>
      <div
        css={{
          margin: 64,
          borderColor: theme.black,
          borderStyle: "solid",
          borderWidth: 1,
          padding: "0 30px 30px",
          [theme.mobile]: {
            margin: "30px auto 0",
            borderWidth: 0,
            alignContent: "center",
          },
        }}
      >
        <Global styles={globalStyles} />
        <div
          css={{
            padding: "0 auto",
          }}
        >
          {children}
        </div>
        <Footer />
      </div>
    </>
  )
}
