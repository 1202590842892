import React from "react"
import { Link, graphql } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import Layout from "../components/Layout"
import theme from "../styles/theme"
import PortableText from "react-portable-text"
import logo from "../assets/ns-logo.png"

const Post = ({
  data: {
    page: { title, mainImage, _rawBody, publishedAt },
  },
}) => (
  // <div>{title}</div>
  <>
    <Link to="/">
      <img
        src={logo}
        alt="No Shortcuts logo"
        width={54}
        css={{
          display: "block",
          height: 54,
          width: "auto",
          margin: 10,
        }}
      />
    </Link>
    <Layout>
      <div
        css={{
          maxWidth: 1200,
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
          justifyItems: "center",
          padding: 30,
          margin: "0 auto 100px",
          [theme.mobile]: {
            padding: "0 20px",
          },
          [theme.smallMobile]: {
            padding: 0,
          },
        }}
      >
        <div
          css={{
            ...theme.h1,
            margin: "60px auto",
            textAlign: "center",
            [theme.mobile]: {
              ...theme.h1,
              margin: "20px auto",
            },
            [theme.smallMobile]: {
              margin: "0 auto 20px",
            },
          }}
        >
          {title}
        </div>
        <div css={{ ...theme.subtitle, marginBottom: 60 }}>{publishedAt}</div>
        <div
          css={{
            ...theme.floatBox,
            borderRadius: "50% 50% 0 0",
            maxWidth: 900,
            [theme.tablet]: {
              maxWidth: 700,
            },
          }}
        >
          {mainImage && (
            <SanityImage
              {...mainImage}
              width={900}
              css={{
                display: "block",
                width: "100%",
                maxHeight: 675,
                aspectRatio: "inherit",
                borderColor: theme.black,
                borderStyle: "solid",
                borderWidth: 1,
                borderRadius: "50% 50% 0 0",
                [theme.tablet]: {
                  maxHeight: 525,
                },
              }}
            />
          )}
          <PortableText
            content={_rawBody}
            css={{
              ...theme.postDetails,
              padding: "60px 80px 30px",
              background: theme.white,
              [theme.smallMobile]: {
                padding: "20px 30px 30px",
              },
            }}
          />
        </div>
      </div>
    </Layout>
  </>
)

export default Post

export const query = graphql`
  query PostQuery($_id: String!) {
    page: sanityPost(_id: { eq: $_id }) {
      title
      mainImage {
        ...ImageWithPreview
      }
      _rawBody
      publishedAt(formatString: "MMMM Do, YYYY")
    }
  }
`
