import React from "react"
import { graphql } from "gatsby"
import { Global } from "@emotion/core"
import theme, { globalStyles } from "../styles/theme"
import PropTypes from "prop-types"
import BlogPostTile from "../components/BlogPostTile"
import Layout from "../components/Layout"

const IndexPage = ({ data }) => {
  return (
    <>
      <Layout>
        <div css={{ paddingTop: 30 }}>
          <Global styles={globalStyles} />
          <h1
            css={{
              ...theme.h1,
              paddingBottom: 60,
              [theme.mobile]: {
                ...theme.h1,
                paddingBottom: 30,
              },
            }}
          >
            {data.settings.title}
          </h1>
          <h2
            css={{
              ...theme.subtitle,
              paddingBottom: 30,
              borderBottom: "solid black 1px",
              [theme.mobile]: {
                paddingBottom: 20,
              },
            }}
          >
            {data.settings.subtitle}
          </h2>
          <div
            css={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: "10px 60px",
              padding: 60,
              justifyContent: "center",
              justifyItems: "center",
              "@media (max-width: 1300px)": {
                gridTemplateColumns: "1fr",
              },
              [theme.tablet]: {
                gridGap: 0,
                padding: 30,
              },
            }}
          >
            {data.posts.nodes &&
              data.posts.nodes.map((post) => {
                return <BlogPostTile key={post.id} {...post} />
              })}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPageQuery {
    settings: sanitySiteSettings {
      title
      subtitle
      description
      keywords
    }
    posts: allSanityPost(sort: { order: DESC, fields: publishedAt }) {
      nodes {
        ...BlogPostTile
      }
    }
  }
`

IndexPage.propTypes = {
  settings: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    description: PropTypes.text,
    keywords: PropTypes.arrayOf(PropTypes.string),
  }),
  allSanityPost: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      publishedAt: PropTypes.string,
      title: PropTypes.string,
      _rawExcerpt: PropTypes.text,
      slug: PropTypes.shape({
        current: PropTypes.string.isRequired,
      }).isRequired,
      mainImage: PropTypes.shape({
        asset: PropTypes.shape({
          _key: PropTypes.string.isRequired,
        }),
      }).isRequired,
    })
  ).isRequired,
}
