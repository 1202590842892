import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import theme from "../styles/theme"
import iconInstagram from "../assets/instagram-duotone.png"
import iconTwitter from "../assets/twitter-duotone.png"
import iconLinkedIn from "../assets/linkedin-duotone.png"

const Footer = () => {
  const {
    data: {
      title,
      siteAuthor,
      residence,
      twitter,
      linkedIn,
      instagram,
      keywords,
      copyright,
    },
  } = useStaticQuery(query)

  return (
    <div
      css={{
        display: "grid",
        gridTemplateColumns: "1fr 200px",
        gridGap: 20,
        paddingTop: 30,
        borderTop: "solid black 1px",
        [theme.mobile]: {
          gridTemplateColumns: "1fr 160px",
          gridGap: 40,
        },
        "@media (max-width: 500px)": {
          display: "block",
          textAlign: "center",
        },
      }}
    >
      <div>
        <h3 css={{ ...theme.subtitle, color: theme.loden, marginBottom: 20 }}>
          {title}
        </h3>
        <div css={{ ...theme.tags, marginBottom: 6 }}>
          {siteAuthor} {residence}
        </div>
        <div css={{ ...theme.tags, marginBottom: 10 }}>{copyright}</div>
      </div>
      <div>
        <div
          css={{
            display: "grid",
            gridTemplateColumns: "60px 60px 60px",
            columnGap: 10,
            justifyContent: "space-between",
            justifyItems: "right",
            marginBottom: 10,
            [theme.mobile]: {
              gridTemplateColumns: "40px 40px 40px",
              // columnGap: 0,
            },
            "@media (max-width: 500px)": {
              justifyContent: "center",
              justifyItems: "center",
              marginTop: 20,
            },
          }}
        >
          <Link to={instagram}>
            <img
              src={iconInstagram}
              alt="Instagram"
              width={40}
              css={{
                display: "block",
                height: 40,
                width: "auto",
                [theme.tablet]: {
                  height: 30,
                },
              }}
            />
          </Link>
          <Link to={twitter}>
            <img
              src={iconTwitter}
              alt="Twitter"
              width={40}
              css={{
                display: "block",
                height: 40,
                width: "auto",
                [theme.tablet]: {
                  height: 30,
                },
              }}
            />
          </Link>
          <Link to={linkedIn}>
            <img
              src={iconLinkedIn}
              alt="LinkedIn"
              width={40}
              css={{
                display: "block",
                height: 40,
                width: "auto",
                [theme.tablet]: {
                  height: 30,
                },
              }}
            />
          </Link>
        </div>
        <div
          css={{
            ...theme.tags,
            lineHeight: 2,
            marginLeft: 5,
            textAlign: "right",
            "@media (max-width: 500px)": {
              textAlign: "center",
            },
          }}
        >
          {keywords.join(" · ")}
        </div>
      </div>
    </div>
  )
}

export default Footer

const query = graphql`
  {
    data: sanitySiteSettings {
      title
      siteAuthor
      residence
      twitter
      linkedIn
      instagram
      keywords
      copyright
    }
  }
`
