import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import theme from "../styles/theme"

const BlogPostTile = ({
  title,
  body,
  mainImage,
  slug,
  id,
  props,
  author,
  publishedAt,
}) => (
  <div
    css={{
      width: 500,
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
      alignItems: "center",
      padding: 30,
      [theme.mobile]: {
        width: 450,
      },
      [theme.smallMobile]: {
        width: 350,
        padding: 20,
      },
    }}
  >
    <Link
      to={slug.current}
      key={slug.current}
      css={
        {
          // textDecoration: "none",
          // textColor: theme.black,
          // borderRadius: "50%",
          // ...theme.floatBox,
        }
      }
    >
      {mainImage && (
        <SanityImage
          {...mainImage}
          width={500}
          css={{
            display: "block",
            width: 500,
            height: 375,
            borderColor: theme.black,
            borderStyle: "solid",
            borderWidth: 1,
            borderRadius: "50% 50% 0 0",
            ...theme.floatBox,
            [theme.mobile]: {
              width: 400,
              height: 300,
            },
            [theme.smallMobile]: {
              width: 360,
              height: 270,
            },
            "@media (max-width: 450px)": {
              width: 320,
              height: 240,
            },
          }}
        />
      )}
      <div
        css={{
          ...theme.postTitle,
          margin: "16px auto 32px",
          maxWidth: 425,
          textAlign: "center",
          padding: "20px 20px 0",
        }}
      >
        {title}
      </div>
    </Link>
  </div>
)

export default BlogPostTile

export const query = graphql`
  fragment BlogPostTile on SanityPost {
    title
    slug {
      current
    }
    publishedAt(formatString: "MMMM Do, YYYY")
    id
    mainImage {
      ...ImageWithPreview
    }
  }
`

BlogPostTile.propTypes = {
  sanityPost: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.shape({
      current: PropTypes.string.isRequired,
    }).isRequired,
    publishedAt: PropTypes.string,
    id: PropTypes.string,
    mainImage: PropTypes.shape({
      asset: PropTypes.shape({
        _key: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }),
}
